<template>
  <div class="index" :style="{minHeight: minHeight}">
    <van-sticky>
      <van-nav-bar left-arrow title="纠错记录"  @click-left="onClickLeft" :placeholder="true" />

    </van-sticky>


    <div class="list" v-if="list.length!==0">

      <div  class="card" v-for="(item) in list" :key="item.id" >
        <div>
          <div class="swipe_title">
            {{item.content}}
          </div>
          <div class="swipe_title_sub" >
            医学公式：{{ item.chineseName}}
          </div>
          <div class="swipe_title_sub" >
            提交时间：{{ item.createdTime}}
          </div>
        </div>


        <span class="swipe_title_sub" :style="{color:item.status===1?'#0bc600':'#1A9CFC'}">
          {{item.status===1?'已处理':'待处理'}}
        </span>

      </div>

    </div>

    <div v-else>
      <van-empty  description="暂无内容" />
    </div>


  </div>
</template>


<script>

import {getMedicalError_log} from "@/service/api";

export default {
  name: "medicalErrorLog",

  data(){
    return{
      list:[],
      memberId:this.$route.query.memberId,
      minHeight: window.innerHeight -100+ 'px',
    }
  },
  mounted() {
    this.getList()
  },
  methods:{

    async getList(){
      const res = await getMedicalError_log()
      if (res.statuscode===200){
        this.list=res.data
      }
    },
    onClickLeft(){
      window.history.back();
    },
  }
}
</script>
<style scoped lang="scss">
//::v-deep .van-nav-bar__title{
//  margin:0 10px !important;
//}
::v-deep .van-nav-bar .van-icon{
  font-size: 20px;
  color: #000000;
  margin-right:10px !important;
}

.index{
  padding-bottom: 60px;
  background: white;
  height: auto;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  .nav{
    display: flex;
    align-items: center;
    .card_text{
      display: flex;
      align-items: center;
      font-size:14px;
      color: #999999;
      .card_name{
        font-size: 15px;
        color: #333333;
      }
    }
  }
  .list{
    //margin: 10px;
    background: white;
    border-radius: 6px;
    //padding-bottom: 20px;
    .card{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:10px 15px;
      border-bottom: #F6F6F6 1px solid;
    }
    .swipe_title_sub{
      margin: 5px 0;
      color: #999999;
      font-size: 13px;
    }
  }
}


.popup-menu {
  position: absolute;
  background: white;
  border: 1px solid #f1f1f1;
  padding: 8px;
  right: 0;
  font-size: 13px;
  z-index: 1000; /* 确保下拉框在最前面 */
}
.list-item {
  position: relative; /* 使下拉框相对于列表项定位 */
  margin-bottom: 20px; /* 添加间距以避免重叠 */
}
</style>